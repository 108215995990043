<template>
  <div class="home">
    <HeaderComponent/>
    <div class="index">
      <div class="section3_1">
        <div class="wrap">
          <div class="left">
            <img src="images/me1.png" class="me1" alt="">
            <img src="images/me2.png" class="me2" alt="">
            <router-link to="/create_token" class="more">Create Token</router-link>
            <a href="#" class="how">How it works</a>
          </div>
          <div class="center">
            <el-carousel class="el-carousel">
              <el-carousel-item class="el-carousel-item" v-for="carousel in carousels">
                <img :src="carousel.imageUrl" alt="">
              </el-carousel-item>
            </el-carousel>
            <!--            <div class="swiper-container">-->
            <!--              <div class="swiper-wrapper">-->
            <!--                <div class="swiper-slide">-->
            <!--                  <img src="images/banner.jpg" alt="">-->
            <!--                </div>-->
            <!--                <div class="swiper-slide">-->
            <!--                  <img src="images/banner.jpg" alt="">-->
            <!--                </div>-->
            <!--                <div class="swiper-slide">-->
            <!--                  <img src="images/banner.jpg" alt="">-->
            <!--                </div>-->
            <!--                <div class="swiper-slide">-->
            <!--                  <img src="images/banner.jpg" alt="">-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="swiper-pagination"></div>-->
            <!--            </div>-->
          </div>
          <div class="right">
            <p>
              0x0c...817024 <span>Sold</span> 0.88BNB
            </p>
            <p>
              <span class="icon"><img src="images/cm.png" alt=""></span>
            </p>
          </div>
        </div>
      </div>
      <div class="section4">
        <div class="wrap">
          <div class="title">Listed on Pancake Swap</div>
          <div class="list">
            <div class="item" v-for="item in token_launched"
                 :key="item.id"
                 @click="toDetail(item.address)">
              <div class="s1">
                <div class="icon">
                  <img src="images/user.png" alt="" v-if="!item.image_url" class="icon_img">
                  <img :src="item.image_url" alt="" v-if="item.image_url" class="icon_img">
                </div>
                <div class="infor">
                  <div class="name">{{ item.name }}</div>
                  <div class="dec">created by: {{ short_address(item.creator) }}</div>
                </div>
              </div>
              <div class="s2">
                <img src="images/user2.png" alt="" v-if="!item.image_url" class="luanched_img">
                <img :src="item.image_url" alt="" v-if="item.image_url" class="luanched_img">
                <div class="infor">
                  <div class="lef">
                    <div class="m1">Market Cap: ${{ (item.market_cap_in_r64 * eth_price).toFixed(2) }}</div>
                    <div class="m2">{{ item.launch_progress }}%</div>
                  </div>
                  <div class="ris">
                    <img src="images/ic2d.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="information">
            <input type="text" class="search" placeholder="Search Token"
                   v-model="search_for_token"
                   @keyup.enter="onClickSearch">
            <div class="right">
              <div class="item">
                <img src="images/cirl.png" alt="">
                <span>Listed on PancakeSwap</span>
              </div>
              <div class="item">
                <select name="" id="">
                  <option value="">Time</option>
                </select>
              </div>
              <div class="item">
                <select name="" id="">
                  <option value="">All Token</option>
                </select>
              </div>
              <div class="item">
                <a href="#"><img src="images/dm1.png" alt=""></a>
              </div>
              <div class="item">
                <a href="#"><img src="images/dm2.png" alt=""></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section5">
        <div class="wrap">
          <div class="item"
               v-for="item in token_list"
               :key="item.id"
               @click="toDetail(item.address)">
            <div class="radius">
              <div class="imgbox">
                <img src="images/pic.png" alt="" v-if="!item.image_url" style="aspect-ratio: 1 / 1">
                <img :src="item.image_url" alt="" v-if="item.image_url" style="aspect-ratio: 1 / 1">
              </div>
              <div class="con">
                <div class="title">{{ item.name }}</div>
                <div class="dec" style=" aspect-ratio: 170 / 60">{{ item.description }}</div>
                <div class="infor">
                  <div class="lef">created by: <br>{{ short_address(item.creator) }}</div>
                  <div class="ris">
                    <a target="_blank" :href="item.website_link"><img src="images/id1.png" alt=""></a>
                    <a target="_blank" :href="item.telegram_link"><img src="images/id2.png" alt=""></a>
                    <a target="_blank" :href="item.twitter_link"><img src="images/id3.png" alt=""></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="con2">
              <div class="lef">
                <div class="txt1">Market Cap:${{ (item.market_cap_in_r64 * eth_price).toFixed(2) }}</div>
                <div class="txt2">{{ item.launch_progress }}%
<!--                  <b>+8%</b>-->
                </div>
              </div>
              <div class="ris">
                <img src="images/ic2d.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <Pagination
            :totalPages="totalPages"
            :currentPage="currentPage"
            @page-changed="handlePageChange"
        />
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import router from "@/router";
import Pagination from "@/components/Pagination.vue";
import axios from "@/utils/axios";
import {short_address} from "../utils/utils";
import {ElMessage} from "element-plus";

export default {
  name: 'HomeView',
  components: {
    Pagination,
    FooterComponent,
    HeaderComponent,
  },
  data: function () {
    return {
      carousels: [
        // {
        //   imageUrl: "/images/pic1.png",
        // },
        // {
        //   imageUrl: "images/pic2.png",
        // },
        // {
        //   imageUrl: "https://33bnb.meme/images/banner.jpg",
        // }
      ],
      page: 1,
      currentPage: 1,
      totalPages: 10,
      pageSize: 8,
      token_list: [],
      token_launched: [],
      eth_price: 0,
      search_for_token: ""
    }
  },
  computed: {},
  methods: {
    short_address,
    toDetail(tokenAddress) {
      router.push('/detail?token=' + tokenAddress)
    },
    handlePageChange(num) {
      console.log('handlePageChange', num)
    },
    async load() {
      const load_resp = await axios.post('/api/token_list', {
        page: this.page,
        pageSize: this.pageSize
      })
      if (load_resp.data.code === 0) {
        this.token_list = load_resp.data.data.tokens
        this.eth_price = load_resp.data.data.eth_price;
        this.totalPages = Math.ceil(load_resp.data.data.total / this.pageSize); // Calculate total pages
      }

      const token_launched_resp = await axios.post('/api/token_launched',)
      if (token_launched_resp.data.code === 0) {
        this.token_launched = token_launched_resp.data.data.tokens
        // this.eth_price = token_launched_resp.data.data.eth_price;
      }


      const load_carousel_resp = await axios.get('/api/carousel/homepage');
      if (load_carousel_resp.data.code === 0) {
        this.carousels = load_carousel_resp.data.data.carousels;
      }
    },
    async onClickSearch() {
      if (this.search_for_token) {
        const load_resp = await axios.get("/api/token/" + this.search_for_token);
        if (load_resp.data.code === 0 && load_resp.data.data.token != null) {
          await router.push('/detail?token=' + this.search_for_token)
        } else {
          ElMessage.error("Token not found")
        }
      }
    },
  },
  mounted() {
    this.load()
  },
  unmounted() {
  }
}
</script>

<style scoped>
.el-carousel {
  width: 100%;
  height: calc(349 / 1920 * 100vw); /* 允许高度自适应 */
  border-radius: 20px;
  //aspect-ratio: 799 / 337; /* 保持宽高比 */
  //margin-bottom: 16px;
}

.el-carousel-item {
  width: 100%;
  height: calc(349 / 1920 * 100vw); /* 允许高度自适应 */

  //aspect-ratio: 799 / 337; /* 保持宽高比 */
}

.el-carousel img {
  width: 100%; /* 确保图片宽度为100% */
  height: auto; /* 允许高度自适应 */
  object-fit: fill; /* 保持图片比例，裁剪多余部分 */
}

.icon_img {
  border-radius: 50% !important; /* 将图片变成圆形 */
  object-fit: cover; /* 确保图片覆盖整个圆形区域 */
  overflow: hidden; /* 隐藏超出圆形区域的部分 */
  aspect-ratio: 1 / 1 !important; /* 设置宽高比为 1:1 */
}

.luanched_img {
  border-radius: 10% !important; /* 将图片变成圆形 */
  object-fit: cover; /* 确保图片覆盖整个圆形区域 */
  overflow: hidden; /* 隐藏超出圆形区域的部分 */
  aspect-ratio: 4 / 3 !important; /* 设置宽高比为 1:1 */
}
</style>

