import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CreateTokenView from "@/views/CreateTokenView.vue";
import AirDropView from "@/views/AirDropView.vue";
import RankingView from "@/views/RankingView.vue";
import DetailView from "@/views/DetailView.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/create_token',
        name: 'CreateToken',
        component: CreateTokenView
    },
    {
        path: '/launchPad',
        name: 'launchPad',
        component: AirDropView
    },
    {
        path: '/ranking',
        name: 'Ranking',
        component: RankingView
    },
    {
        path: '/detail',
        name: 'Detail',
        component: DetailView
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
