import {ethers} from "ethers";
import {FactoryAbi} from "@/store/TokenFactory";
import {UniswapV2FactoryAbi} from "@/store/UniswapV2FactoryAbi";
import {UniswapV2Router02Abi} from "@/store/UniswapV2Router02Abi";
import {BondingCurveAbi} from "@/store/BondingCurveAbi";
import {LaunchPadAbi} from "@/store/LaunchPadAbi";
import {launchpad_address} from "@/store";

const rpc_url = "https://wiser-broken-sanctuary.bsc.quiknode.pro/6324ad8d0046fc5f18573701756592a68891a025";
export const uni_factory_address = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73"
export const uni_router_address = "0x10ED43C718714eb63d5aA57B78B54704E256024E";


export const default_provider = new ethers.JsonRpcProvider(rpc_url);

export const default_factory = new ethers.Contract(launchpad_address, LaunchPadAbi, default_provider);
export const default_uni_factory_contract = new ethers.Contract(uni_factory_address, UniswapV2FactoryAbi, default_provider);
export const default_router = new ethers.Contract(uni_router_address, UniswapV2Router02Abi, default_provider);
