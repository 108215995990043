<script>
import FooterComponent from "@/components/FooterComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import Pagination from "@/components/Pagination.vue";
import axios from "@/utils/axios";
import {formatNumber, short_address, timeAgo} from "../utils/utils";
import {ElMessage} from "element-plus";
import {ethers} from "ethers";
import {LaunchPadAbi} from "@/store/LaunchPadAbi";
import {launchpad_address, uni_factory_address, uni_router_address, weth_address} from "@/store";
import {mapState} from "vuex";
import {getSigner} from "@/utils/wallet";
import {TokenAbi} from "@/store/TokenAbi";
import {createChart} from "lightweight-charts";
import {UniswapV2FactoryAbi} from "@/store/UniswapV2FactoryAbi";
import {UniswapV2Router02Abi} from "@/store/UniswapV2Router02Abi";
import {ChainId, CurrencyAmount, Percent, Token, TradeType} from "@uniswap/sdk-core";
import {PairAbi} from "@/store/PairAbi";
import {Pair, Route, Trade} from "@uniswap/v2-sdk";
import {default_factory, default_provider, default_uni_factory_contract} from "@/utils/ethRpc";

export default {
  name: "DetailView",
  components: {Pagination, HeaderComponent, FooterComponent},
  data: function () {
    return {
      token: {
        name: 'Loading...',
        symbol: 'LD',
        creatorAddress: 'N/A',
        iconUrl: '',
        marketCap: 0,
        bondingCurveProgress: 0,
        remain: 0,
        collateral: 0,
        state: '',
        virtualLiquidity: 0,
        VolumeIn24: 0,
        rise: 0,
        price_in_r64: 0,
        launch_progress: 0,
        image_url: '',
        pair_address: null,
        history_list: [],
      },
      can_buy_amount: 0,
      can_sell_amount: 0,
      comment: '', // New property for comment input
      commentLength: 0, // To track the length of the comment
      maxCommentLength: 256, // Maximum allowed length
      comments: [], // To hold submitted comments
      tab: 'buy',
      amount: 0,
      token_amount: 0,
      currentPage: 1,
      totalPages: 10,
      token_address: "",
      eth_balance: 0,
      token_balance: 0,
      slippage_tolerance: 5,
      slippage_tolerance_temp: '',
      eth_price: 0,
      holders: [],
      isVisible: false,
      tab2: 'comment',
      loading: false,
      loading_amount: false,
      history_list: [],
    };
  },
  computed: {
    ...mapState(['wallet_address', 'wallet_type']), // 映射 state
  },
  methods: {
    formatNumber,
    timeAgo,
    short_address,
    async outerBuy() {
      try {
        const signerInfo = await getSigner(this.wallet_type); // Get the signer information

        // Check if signerInfo is an array with two elements
        let provider, signer;
        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        if (!this.wallet_address) {
          throw new Error('Wallet address is not defined');
        }

        const decimals = 18;

        const uni_factory_contract = new ethers.Contract(uni_factory_address, UniswapV2FactoryAbi, signer);
        const pair_address = await uni_factory_contract.getPair(weth_address, this.token_address);
        console.log('pair_address', pair_address);


        const weth = new Token(ChainId.BNB, weth_address, decimals, "weth", "weth");
        const unknown = new Token(ChainId.BNB, this.token_address, decimals, this.token.symbol, this.token.name);

        const pairContract = new ethers.Contract(pair_address, PairAbi, signer);
        const reserves = await pairContract.getReserves()
        let reserve_weth, reserve_token;
        if (this.token.token0_address === weth_address) {
          reserve_weth = reserves[0];
          reserve_token = reserves[1];
        } else {
          reserve_token = reserves[0];
          reserve_weth = reserves[1];
        }
        console.log('reserves', reserves)

        const WETH_UNKNOWN = new Pair(
            CurrencyAmount.fromRawAmount(weth, reserve_weth.toString()),
            CurrencyAmount.fromRawAmount(unknown, reserve_token.toString())
        );

        const WETH_TO_UNKNOWN = new Route([WETH_UNKNOWN], weth, unknown);
        const trade = new Trade(WETH_TO_UNKNOWN, CurrencyAmount.fromRawAmount(weth, ethers.parseEther(this.amount.toString()).toString()), TradeType.EXACT_INPUT);

        console.log('trade', trade);

        // Set slippage tolerance to 5%
        const slippageTolerance = new Percent(this.slippage_tolerance.toString(), '100'); // 5% slippage
        const minimumAmountOut = trade.minimumAmountOut(slippageTolerance);
        const amountOut = ethers.parseUnits(minimumAmountOut.toSignificant(18), decimals); // Amount of tokens to receive

        const path = [weth_address, this.token_address];

        // Initiate the transaction
        const router = new ethers.Contract(uni_router_address, UniswapV2Router02Abi, signer);

        console.log('params', [
          amountOut,
          path,
          this.wallet_address,
          Math.floor(Date.now() / 1000) + 60 * 20,
          {
            value: ethers.parseEther(this.amount.toString()).toString()
          }
        ]);

        const tx = await router.swapExactETHForTokens(
            amountOut,
            path,
            this.wallet_address,
            Math.floor(Date.now() / 1000) + 60 * 20,
            {
              value: ethers.parseEther(this.amount.toString()).toString()
            }
        );

        return tx;

      } catch (e) {
        if (e.reason) {
          ElMessage.error(e.reason)
        } else if (e.shortMessage) {
          ElMessage.error(("Transaction failed: " + e.shortMessage));
        } else {
          ElMessage.error(e)
        }
      }
    },
    async outerSell() {
      try {
        const signerInfo = await getSigner(this.wallet_type); // Get the signer information
        let provider, signer;

        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        if (!this.wallet_address) {
          throw new Error('Wallet address is not defined');
        }

        const decimals = 18;
        const uni_factory_contract = new ethers.Contract(uni_factory_address, UniswapV2FactoryAbi, signer);
        const pair_address = await uni_factory_contract.getPair(this.token_address, weth_address);
        console.log('pair_address', pair_address);

        const token_contract = new ethers.Contract(this.token_address, TokenAbi, signer);
        const token_balance = await token_contract.balanceOf(this.wallet_address);
        console.log("token_balance", token_balance)

        // Check allowance
        const router = new ethers.Contract(uni_router_address, UniswapV2Router02Abi, signer);
        const allowance = await token_contract.allowance(this.wallet_address, uni_router_address);
        console.log("allowance", allowance)

        const weth = new Token(ChainId.BNB, weth_address, decimals, "weth", "weth");
        const unknown = new Token(ChainId.BNB, this.token_address, decimals, this.token.symbol, this.token.name);

        if ((Number)(ethers.formatEther(allowance).toString()) < this.token_amount) {
          // If allowance is insufficient, approve the router
          console.log("Approval")
          const txApprove = await token_contract.approve(uni_router_address, ethers.MaxUint256);
          console.log('Approval transaction hash:', txApprove.hash);
          ElMessage.success('Approval submitted: ' + txApprove.hash);
          await txApprove.wait(); // Wait for the approval transaction to be mined
          console.log('Approval confirmed');
          ElMessage.success('Approval confirmed');
        }

        const pairContract = new ethers.Contract(pair_address, PairAbi, signer);
        const reserves = await pairContract.getReserves();
        let reserve_weth, reserve_token;
        if (this.token.token0_address === weth_address) {
          console.log("asdasda")
          reserve_weth = reserves[0];
          reserve_token = reserves[1];
        } else {
          reserve_token = reserves[0];
          reserve_weth = reserves[1];
        }

        console.log('reserves', reserves);

        const TOKEN_WETH = new Pair(
            CurrencyAmount.fromRawAmount(unknown, reserve_token.toString()),
            CurrencyAmount.fromRawAmount(weth, reserve_weth.toString())
        );

        const TOKEN_TO_WETH = new Route([TOKEN_WETH], unknown, weth);
        const token_in = ethers.parseEther(this.token_amount.toString()).toString();
        console.log('token_in', token_in);
        const trade = new Trade(TOKEN_TO_WETH, CurrencyAmount.fromRawAmount(unknown, token_in), TradeType.EXACT_INPUT);

        console.log('trade', trade);

        // Set slippage tolerance to 5%
        const slippageTolerance = new Percent(this.slippage_tolerance.toString(), '100'); // 5% slippage
        const minimumAmountOut = trade.minimumAmountOut(slippageTolerance);
        const amountOut = ethers.parseEther(minimumAmountOut.toExact()); // Amount of WETH to receive

        console.log('amountOut', minimumAmountOut.toExact() + " bnb");

        const path = [this.token_address, weth_address];

        // Initiate the transaction
        const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from the current Unix time

        console.log('params', [
          token_in,
          amountOut,
          path,
          this.wallet_address,
          deadline,
          {}
        ]);

        const tx = await router.swapExactTokensForETH(
            token_in,
            amountOut,
            path,
            this.wallet_address,
            deadline,
            {
              // gasLimit: 1_000_000_000
            }
        );

        return tx;

      } catch (e) {
        if (e.reason) {
          ElMessage.error(e.reason)
        } else if (e.shortMessage) {
          ElMessage.error(("Transaction failed: " + e.shortMessage));
        } else {
          ElMessage.error(e)
        }
      }
    },
    handleCommentInput(event) {
      this.comment = event.target.value;
      this.commentLength = this.comment.length; // Update comment length
    },
    onClickPercent(pct) {
      if (pct === 100) {
        pct = 99;
      }
      this.token_amount = Math.ceil(this.token_balance * pct / 100);
      this.onChangeAmount()
    },
    onClickAmount(amt) {
      this.amount = amt;
      this.onChangeAmount()
    },
    onClickSelectTab(tab) {
      this.tab = tab
    },
    handlePageChange(num) {
      console.log('handlePageChange', num)
    },
    async load(address) {
      const load_resp = await axios.get("/api/token/" + address);
      if (load_resp.data.code === 0) {
        this.token = load_resp.data.data.token;
        this.eth_price = load_resp.data.data.eth_price;

      }
    },
    async loadKline() {
      const url = "/api/kline/" + this.token_address + "?duration=" + 60 * 15 + "&amount=25"
      const load_price_resp = await axios.get(url);
      this.render(load_price_resp.data.data.klines)
    },
    async loadComment(address) {
      const load_resp = await axios.post("/api/comment_list", {
        page: this.page,
        pageSize: this.pageSize,
        tokenAddress: this.token_address
      });
      if (load_resp.data.code === 0) {
        this.comments = load_resp.data.data.comments;
      }
    },
    async loadHistory(address) {
      const load_resp = await axios.post("/api/history_list", {
        page: this.page,
        pageSize: this.pageSize,
        tokenAddress: this.token_address
      });
      if (load_resp.data.code === 0) {
        this.history_list = load_resp.data.data.histories;
      }
    },
    async loadHolder(tokenAddress) {
      const url = '/api/holder/' + tokenAddress;
      const resp = await axios.get(url);
      if (resp.data.code === 0) {
        this.holders = resp.data.data;
      }
    },
    async submitComment() {
      if (!this.wallet_address) {
        ElMessage.error('Please connect wallet first.');
        return;
      }
      if (this.commentLength > 0 && this.commentLength <= this.maxCommentLength) {
        // Handle comment submission (e.g., send to an API)
        console.log("Comment submitted:", this.comment);

        await axios.post('/api/create_comment', {
          tokenAddress: this.token_address,
          creatorAddress: this.wallet_address,
          content: this.comment
        })

        this.comments.push({creatorAddress: this.wallet_address, content: "Just now", text: this.comment}); // Example
        this.comment = ''; // Clear the comment after submission
        this.commentLength = 0; // Reset length


      } else {
        ElMessage.error(`Comment must be between 1 and ${this.maxCommentLength} characters.`);
      }
    },
    async loadErc20Balance(tokenAddress) {
      try {
        const signerInfo = await getSigner(this.wallet_type); // Get the signer information

        // Check if signerInfo is an array with two elements
        let provider, signer;
        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        if (!this.wallet_address) {
          throw new Error('Wallet address is not defined');
        }

        // Create a contract instance for the ERC20 token
        const erc20Abi = [
          // Only include the balanceOf function in the ABI
          "function balanceOf(address owner) view returns (uint256)"
        ];
        const tokenContract = new ethers.Contract(tokenAddress, erc20Abi, provider);

        // Fetch the ERC20 balance
        const balanceBigNumber = await tokenContract.balanceOf(this.wallet_address);
        const balance = ethers.formatUnits(balanceBigNumber, 18); // Format balance to token's decimal (assuming 18 decimals)
        this.token_balance = Number(balance);

        // console.log('ERC20 Balance:', balance);
      } catch (error) {
        console.error('Error loading ERC20 balance:', error);
      }
    },
    render(data) {
      if (!this.$refs.chartContainer) {
        console.log('Render failed: chart container is not available.');
        return;
      }

      //获取this.$refs.chartContainer高度
      const height = this.$refs.chartContainer.clientHeight;
      console.log('this.$refs.chartContainer.clientHeight:', height);

      console.log('Rendering chart with data:', data);

      this.$refs.chartContainer.innerHTML = '';
      const chart = createChart(this.$refs.chartContainer, {
        width: this.$refs.chartContainer.clientWidth,
        height: this.$refs.chartContainer.clientHeight,
        layout: {
          background: {color: '#151718'},
          textColor: '#DDD',
        },
        timeScale: {
          // Adds hours and minutes to the chart.
          timeVisible: true,
          secondsVisible: false
        },
        localization: {
          priceFormatter: (p) => `${p.toFixed(15)}`,
        },
        grid: {
          vertLines: {color: '#444'},
          horzLines: {color: '#444'},
        },
        priceScale: {
          position: 'right',
          autoScale: false, // Disable auto scaling
          min: 1e-14,          // Set minimum value for y-axis
          max: 1e-15       // Set maximum value for y-axis
        },
        minimumWidth: 100,
        priceFormat: {
          minMove: 0.000000001,
          precision: 10,
        },
        debug: true,
      });

      const candlestickSeries = chart.addCandlestickSeries({
        upColor: 'rgba(75, 192, 192, 1)',
        downColor: 'rgba(255, 99, 132, 1)',
        borderVisible: false,
      });

      const volumeSeries = chart.addHistogramSeries({
        color: '#26a69a',
        priceFormat: {
          type: 'volume',
        },
        priceScaleId: '',
      });

      try {
        let formt_data = data.map(item => ({
          time: Math.floor(Date.parse(item.open_time) / 1000), // Keep time as integer (optional)
          open: (Number)(item.open),
          high: (Number)(item.high),
          low: (Number)(item.low),
          close: (Number)(item.close),
          volume: (Number)(item.volume),
        }));
        candlestickSeries.setData(formt_data);
        //调整canvars高度
        chart.resize(this.$refs.chartContainer.clientWidth, height);
        //刷新时间
        // chart.timeScale().fitContent();
        console.log('Render successful', formt_data);
      } catch (error) {
        console.error('Render failed:', error);
      }
    },
    onClickCopyAddress() {
      window.navigator && window.navigator.clipboard && window.navigator.clipboard.writeText(this.token_address);
      ElMessage.success('Address copied to clipboard.');
    },
    async refreshCanBuyAmount() {
      if (this.amount <= 0) {
        this.can_buy_amount = 0;
        return
      }
      this.can_buy_amount = (Number)(await this.estimateBuy(this.token_address, this.amount.toString(), 0))
    },
    async refreshCanSellAmount() {
      if (this.token_amount <= 0) {
        this.can_sell_amount = 0;
        return
      }
      this.can_sell_amount = (Number)(await this.estimateSell(this.token_address, this.token_amount.toString(), 0))
    },
    async refreshCanBuyOnUni() {
      if (this.amount <= 0) {
        this.can_buy_amount = 0;
        return
      }
      const decimals = 18;
      const token_contract = new ethers.Contract(this.token_address, TokenAbi, default_provider);
      const pair_address = await default_uni_factory_contract.getPair(this.token_address, weth_address);
      console.log('pair_address', pair_address);

      const pairContract = new ethers.Contract(pair_address, PairAbi, default_provider);

      const reserves = await pairContract.getReserves();
      const reserve0 = reserves[0];
      const reserve1 = reserves[1];
      console.log('reserves', reserves);

      // Determine which reserve corresponds to which token
      const token0 = await pairContract.token0();
      // const token1 = await pairContract.token1();

      let tokenReserve, wethReserve;

      if (this.token_address.toLowerCase() === token0.toLowerCase()) {
        tokenReserve = reserve0;
        wethReserve = reserve1;
      } else {
        tokenReserve = reserve1;
        wethReserve = reserve0;
      }

      const weth = new Token(ChainId.BASE, weth_address, decimals, "weth", "weth");
      const unknown = new Token(ChainId.BASE, this.token_address, decimals, this.token.symbol, this.token.name);

      const WETH_TOKEN = new Pair(
          CurrencyAmount.fromRawAmount(weth, wethReserve.toString()),
          CurrencyAmount.fromRawAmount(unknown, tokenReserve.toString()),
      );


      const WETH_TO_TOKEN = new Route([WETH_TOKEN], weth, unknown);
      const eth_in = ethers.parseEther(this.amount.toString()).toString();
      console.log('eth_in', eth_in);
      const trade = new Trade(WETH_TO_TOKEN, CurrencyAmount.fromRawAmount(weth, eth_in), TradeType.EXACT_INPUT);

      const slippageTolerance = new Percent(this.slippage_tolerance.toString(), '100'); // 5% slippage
      const minimumAmountOut = trade.minimumAmountOut(slippageTolerance);
      // console.log('minimumAmountOut',minimumAmountOut)
      const amountOut = minimumAmountOut.toSignificant(6);
      this.can_buy_amount = (Number)(amountOut);
    },
    async refreshCanSellOnUni() {
      if (this.token_amount <= 0) {
        this.can_sell_amount = 0;
        return
      }
      const decimals = 18;
      const token_contract = new ethers.Contract(this.token_address, TokenAbi, default_provider);
      const pair_address = await default_uni_factory_contract.getPair(this.token_address, weth_address);
      console.log('pair_address', pair_address);

      const pairContract = new ethers.Contract(pair_address, PairAbi, default_provider);
      const reserves = await pairContract.getReserves();
      const reserve0 = reserves[0];
      const reserve1 = reserves[1];
      console.log('reserves', reserves);

      // Determine which reserve corresponds to which token
      const token0 = await pairContract.token0();
      // const token1 = await pairContract.token1();

      let tokenReserve, wethReserve;

      if (this.token_address.toLowerCase() === token0.toLowerCase()) {
        tokenReserve = reserve0;
        wethReserve = reserve1;
      } else {
        tokenReserve = reserve1;
        wethReserve = reserve0;
      }

      const weth = new Token(ChainId.BASE, weth_address, decimals, "weth", "weth");
      const unknown = new Token(ChainId.BASE, this.token_address, decimals, this.token.symbol, this.token.name);

      const TOKEN_WETH = new Pair(
          CurrencyAmount.fromRawAmount(unknown, tokenReserve.toString()),
          CurrencyAmount.fromRawAmount(weth, wethReserve.toString())
      );

      const TOKEN_TO_WETH = new Route([TOKEN_WETH], unknown, weth);
      const token_in = ethers.parseEther(this.token_amount.toString()).toString();
      console.log('token_in', token_in);
      const trade = new Trade(TOKEN_TO_WETH, CurrencyAmount.fromRawAmount(unknown, token_in), TradeType.EXACT_INPUT);

      const slippageTolerance = new Percent(this.slippage_tolerance.toString(), '100'); // 5% slippage
      const minimumAmountOut = trade.minimumAmountOut(slippageTolerance);
      // console.log('minimumAmountOut',minimumAmountOut)
      const amountOut = minimumAmountOut.toExact()
      this.can_sell_amount = (Number)(amountOut);
    },
    async onChangeAmount() {
      this.loading_amount = true;
      if (this.tab === 'buy' && !this.token.pair_address) {
        await this.refreshCanBuyAmount()
      }
      if (this.tab === 'sell' && !this.token.pair_address) {
        await this.refreshCanSellAmount()
      }
      if (this.tab === 'buy' && this.token.pair_address) {
        await this.refreshCanBuyOnUni()
      }
      if (this.tab === 'sell' && this.token.pair_address) {
        await this.refreshCanSellOnUni()
      }
      this.loading_amount = false;
    },
    Reciprocal(amount) {
      if (amount === 0) {
        return new Percent("100", "100")
      }
      const numerator = BigInt(Math.round((100 - amount) * 100));
      const denominator = BigInt(10000);
      const p = new Percent(numerator.toString(), denominator.toString());
      console.log("p (toFixed):", p.toFixed(2) + "%");
      console.log("p (toSignificant):", p.toSignificant(3) + "%");
      return p
    },
    async estimateSell(tokenAddress, tokenAmountIn, slippageTolerance) {
      const tokenAmountInBig = ethers.parseEther(tokenAmountIn).toString();
      console.log("tokenAmountInBig", tokenAmountInBig)
      try {
        const result = await default_factory.getBNBAmountBySaleWithFee(tokenAddress, tokenAmountInBig.toString())
        console.log("getBNBAmountBySaleWithFee result",result)
        const bnbAmount = result[0].toString()
        const percent = this.Reciprocal(slippageTolerance);
        const bnbToken = new Token(1, '0x1234567890123456789012345678901234567890', 18, 'BNB', 'WBNB');
        const currencyAmount = CurrencyAmount.fromRawAmount(bnbToken, bnbAmount)
        const amountOut = currencyAmount.multiply(percent).toExact()
        return amountOut;

      } catch (e) {
        console.error(e)
        return 0
      }
    },
    async estimateBuy(tokenAddress, bnbAmountIn, slippageTolerance) {
      const bnbAmountInBig = ethers.parseEther(bnbAmountIn)
      console.log("bnbAmountInBig", bnbAmountInBig.toString())
      try {

        const result = await default_factory.getTokenAmountByPurchaseWithFee(tokenAddress, bnbAmountInBig.toString());
        const tokenAmountRaw = result.tokenAmount.toString()
        const percent = this.Reciprocal(slippageTolerance);

        const token = new Token(1, '0x1234567890123456789012345678901234567890', 18, 'Token', 'Token');

        const currencyAmount = CurrencyAmount.fromRawAmount(token, tokenAmountRaw)
        console.log(`指定 18 位有效数字的金额: ${currencyAmount.toExact()} ${token.symbol}`);

        const amountOut = currencyAmount.multiply(percent).toExact()

        return amountOut

      } catch (e) {
        console.error(e)
        return 0;
      }

    },
    async onClickTrade() {
      console.log('onClickTrade', this.token_address)
      if (!this.wallet_address) {
        ElMessage.error('Please connect wallet first.');
        return;
      }

      if (this.loading) {
        return
      }
      this.loading = true

      try {
        const signerInfo = await getSigner(this.wallet_type); // Get the signer information
        // Check if signerInfo is an array with two elements
        let provider, signer;
        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        if (!this.wallet_address) {
          throw new Error('Wallet address is not defined');
        }

        const factory = new ethers.Contract(launchpad_address, LaunchPadAbi, signer)

        if (this.token.pair_address) {
          if (this.tab === 'buy') {
            let tx = await this.outerBuy();
            const receipt = await tx.wait();
            console.log('receipt', receipt)
            if (receipt.status === 1) {
              ElMessage.success('Transaction confirmed');
              await axios.post('/api/create_history', {
                tradeType: this.tab,
                tokenAddress: this.token_address,
                txid: tx.hash,
                amount: this.amount,
                state: this.token.state,
                creatorAddress: this.wallet_address
              })
            }
          } else {
            let tx = await this.outerSell()
            ElMessage.success('Transaction submitted: ' + tx.hash);
            const receipt = await tx.wait();
            console.log('receipt', receipt)
            if (receipt.status === 1) {
              ElMessage.success('Transaction confirmed');
              await axios.post('/api/create_history', {
                tradeType: this.tab,
                tokenAddress: this.token_address,
                txid: tx.hash,
                amount: this.amount,
                state: this.token.state,
                creatorAddress: this.wallet_address
              })
            }
          }
        } else {


          if (this.tab === 'buy') {
            let min = await this.estimateBuy(this.token_address, this.amount.toString(), this.slippage_tolerance);
            let min2 = ethers.parseEther(min.toString()).toString();
            const tx = await factory.purchaseToken(this.token_address,
                min2
                , {
                  value: ethers.parseEther(this.amount.toString())
                });
            console.log('tx', tx.hash)
            ElMessage.success('Transaction submitted: ' + tx.hash);
            const receipt = await tx.wait();
            console.log('receipt', receipt)
            if (receipt.status === 1) {
              ElMessage.success('Transaction confirmed');
              await axios.post('/api/create_history', {
                tradeType: this.tab,
                tokenAddress: this.token_address,
                txid: tx.hash,
                amount: this.amount,
                state: this.token.state,
                creatorAddress: this.wallet_address
              })
            }
          } else if (this.tab === 'sell') {

            const token_contract = new ethers.Contract(this.token_address, TokenAbi, signer);
            const allowance = await token_contract.allowance(this.wallet_address, launchpad_address);
            console.log("allowance", allowance)

            if ((Number)(ethers.formatEther(allowance).toString()) < this.token_amount) {

              const txApprove = await token_contract.approve(launchpad_address, ethers.MaxUint256);
              console.log('Approval transaction hash:', txApprove.hash);
              ElMessage.success('Approval submitted: ' + txApprove.hash);
              await txApprove.wait(); // Wait for the approval transaction to be mined
              console.log('Approval confirmed');
              ElMessage.success('Approval confirmed');

            }

            const amount_in_wei = ethers.parseEther(this.token_amount.toString()).toString();
            console.log('amount_in_wei', amount_in_wei)
            let min = await this.estimateSell(this.token_address, this.token_amount.toString(), this.slippage_tolerance);
            let min2 = ethers.parseEther(min.toString()).toString()
            const tx = await factory.saleToken(this.token_address, amount_in_wei, min2, {})
            ElMessage.success('Transaction submitted: ' + tx.hash);

            const receipt = await tx.wait();
            console.log('receipt', receipt)
            if (receipt.status === 1) {
              ElMessage.success('Transaction confirmed');
              await axios.post('/api/create_history', {
                tradeType: this.tab,
                tokenAddress: this.token_address,
                txid: tx.hash,
                amount: this.token_amount,
                state: this.token.state,
                creatorAddress: this.wallet_address
              })
            }
          }
        }
        this.loading = false;
      } catch (e) {
        // ElMessage.error('Error: ' + e);
        console.log(e)
        if (e.reason) {
          ElMessage.error(e.reason)
        } else if (e.shortMessage) {
          ElMessage.error(("Transaction failed: " + e.shortMessage));
        } else {
          ElMessage.error(e)
        }
        this.loading = false;
      } finally {
        this.loading = false
      }
    },
    async loadEthBalance() {
      try {
        const signerInfo = await getSigner(this.wallet_type); // Get the signer information

        // Check if signerInfo is an array with two elements
        let provider, signer;
        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        if (!this.wallet_address) {
          throw new Error('Wallet address is not defined');
        }

        const balanceBigNumber = await provider.getBalance(this.wallet_address);
        const balance = ethers.formatEther(balanceBigNumber); // Format balance to Ether
        this.eth_balance = (Number)(balance);
        // console.log('BNB Balance:', balance);
      } catch (error) {
        console.error('Error loading ETH balance:', error);
      }
    },
    onClickSave() {
      this.slippage_tolerance = this.slippage_tolerance_temp;
      this.slippage_tolerance_temp = '';
    },
    len(l) {
      let a = 50 + (l / 2);
      return "width:" + a + '%'
    }
  },
  mounted() {
    this.token_address = this.$route.query.token;
    console.log('Retrieved ID:', this.token_address);

    this.load(this.token_address);
    this.loadKline(this.token_address)
    this.loadHolder(this.token_address);
    this.loadComment(this.token_address);
    this.loadHistory(this.token_address);
    this.timer1 = setInterval(() => {
      if (this.wallet_address) {
        this.loadEthBalance()
        this.loadErc20Balance(this.token_address);
      }
    }, 1000);

    this.timer2 = setInterval(() => {
      this.load(this.token_address);
      this.loadKline(this.token_address)
      this.loadHolder(this.token_address);
      this.loadComment(this.token_address);
      this.loadHistory(this.token_address);
    }, 6000);

  },
  unmounted() {
    clearInterval(this.timer1);
    clearInterval(this.timer2);
  }
}
</script>

<template>
  <div>
    <HeaderComponent/>
    <div class="index">
      <div class="section1">
        <div class="wrap">
          <div class="left">
            <div class="block1">
              <div class="title">
                <img src="images/ic1.png" alt="" v-if="!token.image_url">
                <img :src="token.image_url" alt="" v-if="token.image_url" class="icon_img">
                <img src="images/ic2.png" class="sec" alt="">
                <span>{{ token.symbol }}</span>
                <b>|</b>
                <strong>BNB</strong>
              </div>
              <div class="items">
                <div class="item">
                  <div class="tit">${{ (token.market_cap_in_r64 * eth_price).toFixed(2) }}</div>
                  <div class="dec">Market Cap</div>
                  <!--                  <b>+0.04%</b>-->
                </div>
                <div class="item">
                  <div class="tit" v-if="!token.pair_address">${{ formatNumber(token.token1_reserve) }}</div>
                  <div class="tit" v-if="token.pair_address">0</div>
                  <div class="dec">Virtual Liquidity</div>
                </div>
                <div class="item">
                  <div class="tit">${{ (token.trade_volume * eth_price).toFixed(2) }}</div>
                  <div class="dec">Volume</div>
                </div>
                <div class="item">
                  <div class="tit">{{ token.price_in_r64.toFixed(12) }}</div>
                  <div class="dec">Price</div>
                  <strong>BNB</strong>
                </div>
                <div class="item">
                  <div class="tit">{{ short_address(token.address) }}</div>
                  <div class="dec">CA</div>
                  <div class="icon" @click="onClickCopyAddress"><img src="images/icr.png" class="im1" alt=""></div>
                </div>
              </div>
            </div>
            <div class="block2">
              <!--                            <img src="images/charts.png" alt="">-->
              <!--                            <iframe id="tradingview_6aaa1" name="tradingview_6aaa1" src="https://www.dextools.io/461fc3f6-ba64-4882-b2fb-021b5a5c753e" data-widget-options="symbol=Jeff%2FUSD%20-%20DEX&amp;interval=30&amp;widgetbar=%7B%22details%22%3Afalse%2C%22watchlist%22%3Afalse%2C%22news%22%3Afalse%2C%22datawindow%22%3Afalse%2C%22watchlist_settings%22%3A%7B%22default_symbols%22%3A%5B%5D%7D%7D&amp;timeFrames=%5B%7B%22text%22%3A%225y%22%2C%22resolution%22%3A%221W%22%7D%2C%7B%22text%22%3A%221y%22%2C%22resolution%22%3A%221W%22%7D%2C%7B%22text%22%3A%226m%22%2C%22resolution%22%3A%22120%22%7D%2C%7B%22text%22%3A%223m%22%2C%22resolution%22%3A%2260%22%7D%2C%7B%22text%22%3A%221m%22%2C%22resolution%22%3A%2230%22%7D%2C%7B%22text%22%3A%225d%22%2C%22resolution%22%3A%225%22%7D%2C%7B%22text%22%3A%221d%22%2C%22resolution%22%3A%221%22%7D%5D&amp;locale=en&amp;uid=tradingview_6aaa1&amp;clientId=0x8946c177d4bd65d3be1f60d302eefc51937d2e18-arbitrum&amp;userId=0&amp;chartsStorageVer=1&amp;customCSS=css%2Fcustom_dext_light_dark.css%3F1.0.0&amp;autoSaveDelay=2&amp;debug=false&amp;timezone=Asia%2FShanghai&amp;theme=dark" title="Financial Chart" frameborder="0" allowtransparency="true" scrolling="no" allowfullscreen="" style="display: block; width: 100%; height: 400px;">-->
              <!--                            </iframe>-->

              <div class="lw-chart" ref="chartContainer" v-if="!token.pair_address"></div>

              <iframe id="dextools-widget"
                      frameborder="0"
                      style="width: 100%;aspect-ratio: 2100 / 1300;border-radius: 20px"
                      title="DEXTools Trading Chart"
                      v-if="token.pair_address"
                      :src="'https://www.dextools.io/widget-chart/en/bnb/pe-light/'+token.pair_address+'?theme=dark&chartType=2&chartResolution=30&drawingToolbars=false'">
              </iframe>

            </div>
          </div>
          <div class="right">
            <div class="block1"
                 :style="{
              'background': tab !== 'set'?'#e1eadf':'#212d26'
              }"
            >
              <div class="hd">
                <div class="hda"
                     :class="[
                     { 'hide': tab === 'set' }]"
                >
                  <a href="javascript:;" :class="{'current': tab === 'buy'}"
                     @click="onClickSelectTab('buy')"> Buy</a>
                  <a href="javascript:;"
                     :class="[
                      { 'current': tab === 'sell' || tab === 'set' },
                     ]"
                     :style="{
                        'border-radius': tab !== 'set' ?'calc(30/1920*100vw) calc(30/1920*100vw) 0 0':'calc(30/1920*100vw) 0 0 0'
                     }"
                     @click="onClickSelectTab('sell')"> Sell</a>
                </div>
                <a href="javascript:;" class="set" :class="{'current': tab === 'set'}" @click="onClickSelectTab('set')">
                  <img src="images/set.png" alt="">
                </a>
              </div>
              <div class="bd">
                <div class="modelItem" v-show="tab==='buy'">
                  <div class="model">
                    <div class="ms1">
                      <div class="s1">Balance: {{ eth_balance }} BNB</div>
                      <div class="s2">Base on PancakeSwap</div>
                    </div>
                    <div class="ms2">
                      <div class="inputwords">
                        <input type="number" class="words" v-model="amount" min="0" step="0.1" @input="onChangeAmount">
                        <div class="posa">
                          BNB
                          <img src="images/if.png" alt="">
                        </div>
                      </div>
                      <div class="switch">
                        You will receive: {{ can_buy_amount.toFixed(2) }} {{ token.symbol }}
                      </div>
                    </div>
                    <button class="btns" type="button" style="font-size: calc(18 / 1920* 100vw);color: black"
                            @click="onClickTrade">Trade
                    </button>
                    <div class="txt" v-if="token.pair_address">The Pool was migrated to PancakeSwap</div>
                    <div class="txt" v-if="!token.pair_address"></div>
                  </div>
                </div>
                <div class="modelItem" v-show="tab==='sell'">
                  <div id="model3"
                       :style="{
                        'border-radius': tab !== 'set' ?'0 calc(30 / 1920* 100vw) calc(30 / 1920* 100vw) 0':'0 0 calc(30 / 1920* 100vw) 0'
                     }">
                    <div class="ms_1">
                      <p>Available : {{ token_balance }} {{ token.symbol }} </p>
                      <b>Base on PancakeSwap</b>
                    </div>
                    <div class="ms_2">
                      <div class="inputwords">
                        <input type="number" placeholder="0" class="words" v-model="token_amount"
                               @input="onChangeAmount">
                        <div class="posa" style="color: black">
                          BNB
                          <img src="images/if.png" alt="">
                        </div>
                      </div>
                      <div class="dec2">
                        <p>
                          <a href="#" @click="onClickPercent(25)">25%</a>
                        </p>
                        <p>
                          <a href="#" @click="onClickPercent(50)">50%</a>
                        </p>
                        <p>
                          <a href="#" @click="onClickPercent(75)">75%</a>
                        </p>
                        <p>
                          <a href="#" @click="onClickPercent(100)">100%</a>
                        </p>
                      </div>

                    </div>
                    <div style="clear: both;margin: 5px;text-align: center">
                      You will receive: {{ can_sell_amount.toFixed(10) }} BNB
                    </div>
                    <button class="btn" type="button" @click="onClickTrade">Trade</button>
                    <div class="desc2" v-if="token.pair_address">The Pool was migrated to PancakeSwap</div>
                    <div class="desc2" v-if="!token.pair_address"></div>
                  </div>
                </div>
              </div>
              <div id="setmodel" style="display: block;    border-radius:  calc(30 / 1920* 100vw) 0 0 0;"
                   v-show="tab==='set'">
                <div class="model1">
                  <div class="ms1">
                    <div class="inputwords">
                      <input type="number" min="1" max="99" placeholder="Max Slippage" class="words"
                             v-model="slippage_tolerance_temp">
                      <div class="posa">
                        {{ slippage_tolerance }}%
                      </div>
                    </div>
                    <div class="dec">Modify the max slippages for your trades. Default: 5%</div>
                  </div>
                  <div class="tiswti">
                    <div class="tit">MEV protection</div>
                    <div class="switchModel">
                      <input type="checkbox" name="Storage" id="switch1">
                      <label for="switch1">
                        <em></em>
                      </label>
                    </div>
                  </div>
                  <div class="desc">After MEV protection is turned on, front-running and sandwich attacks will be
                    effectively prevented. (Metamask wallet only)
                  </div>
                  <button class="btn" type="button" @click="onClickSave">SAVE</button>
                </div>
              </div>
            </div>
            <div class="block2">
              <div class="ms1">
                <div class="icon">
                  <img src="images/user.png" alt="" v-if="!token.image_url">
                  <img :src="token.image_url" alt="" v-if="token.image_url" class="icon_img">
                </div>
                <div class="infor">
                  <div class="name">{{ token.name }}</div>
                  <div class="dec">created by: {{ short_address(token.creator) }}</div>
                </div>
                <a target="_blank" :href="'https://bscscan.com/token/'+token.address" class="share">
                  <img src="images/arr.png" alt="">
                </a>
              </div>
              <div class="ms2">{{ token.description }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section2">
        <div class="wrap">
          <div class="left">
            <div class="modeltab" style="display: block" v-show="tab2==='comment'">
              <div class="title">
                <img src="images/titleimg2.png" alt="">
                <a href="javascript:;" class="wallet" @click="tab2='history'">
                  <img src="images/wl.png" alt="">
                  <span>Trades</span>
                </a>
              </div>
              <div class="tit">Total {{ comments.length }}</div>
              <div class="items">
                <div class="item" v-for="(item, index) in comments" :key="index">
                  <div class="s1">
                    <img src="images/cu1.png" alt="">
                    <div class="infor">
                      <div class="s1">{{ short_address(item.creatorAddress) }}</div>
                      <div class="s2">{{ timeAgo(item.createdAt) }}</div>
                    </div>
                  </div>
                  <div class="s2">
                    {{ item.content }}
                  </div>
                </div>
              </div>
              <div class="textareaform">
                <textarea name="" style="color: black" class="words" placeholder="Write your comment..."
                          v-model="comment"
                          @input="handleCommentInput"
                          :maxlength="maxCommentLength">

                          id=""></textarea>
                <div class="totals">{{ commentLength }} / {{ maxCommentLength }}</div>
                <button class="btn" type="button" @click="submitComment">Submit</button>
              </div>
              <div class="pagesize">
                <div class="brad">
                  <a href="#" class="">
                    <img src="images/left.png" alt="">
                  </a>
                  <a href="#" class="current">1</a>
                  <a href="#">2</a>
                  <a href="#">3</a>
                  <a href="#">4</a>
                  <a href="#">5</a>
                  <input type="text" class="words" placeholder="...">
                  <a href="#">12</a>
                  <a href="#">
                    <img src="images/right.png" alt="">
                  </a>
                </div>
              </div>
            </div>
            <div class="modeltab" style="display: block" v-show="tab2==='history'">
              <div class="tableDetail">
                <div class="title">
                  <div class="titlePic">
                    <img src="images/titleimg.png" alt="">
                  </div>
                  <a href="javascript:;" class="wallet" @click="tab2='comment'">
                    <img src="images/wl2.png" alt="">
                    <span>Comments</span>
                  </a>
                </div>
                <div class="tablelist">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" class="">
                    <tr>
                      <th>ACCOUNT&DATE</th>
                      <th>USD</th>
                      <th>BNB</th>
                      <th>{{ token.symbol }}</th>
                      <th>DATE</th>
                      <th>TXN</th>
                    </tr>
                    <tr :class="history.tradeType === 'buy' ? 'red' : 'green'" v-for="history in history_list"
                        :key="history.id">
                      <td>
                        <div class="txt1">
                          <img src="images/ifg1.png" alt="">
                          <span>{{ short_address(history.creatorAddress) }}</span>
                        </div>
                      </td>
                      <td>{{ ((history.ethAmount + history.fee) * this.eth_price).toFixed(6) }}</td>
                      <td>{{ (history.ethAmount + history.fee).toFixed(6) }}</td>
                      <td>{{ formatNumber(history.tokenAmount) }}</td>
                      <td>{{ timeAgo(history.createdAt) }}</td>
                      <td>
                        <a :href="'https://bscscan.com/tx/'+history.txid" target="_blank" class='cur'>
                          <img src="images/link.png" alt="">
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="right" >
            <div class="ms1" v-if="!token.pair_address">
              <div class="item" style="width: 40%;">
                <div class="s1">Bonding Curve</div>
                <div class="s2">{{ token.launch_progress.toFixed(2) }}%</div>
              </div>
              <div class="item" style="width: 60%;">
                <div class="s1">For Sale</div>
                <div class="s2">{{ (100 - token.launch_progress).toFixed(2) }}%</div>
              </div>
            </div>
            <div class="ms2" v-if="!token.pair_address">
              token1_reserve
              <p>There are <b>{{ (token.token1_reserve - 270000000).toFixed(2)}} {{ token.name }}</b> still available for sale in the bonding
                curve and there is <b> {{ (token.token0_reserve - 8.75).toFixed(10)}}
                  BNB</b> in the bonding curve.</p>
              <p>When the market cap reaches <b>${{ 25 * eth_price }} USD</b> all the liquidity from the bonding curve
                will be deposited
                into Pancake and burned. Progression increases as the price goes up.</p>
            </div>
            <div class="ms3">
              <div class="model1">
                <div class="s1">Holder& <br>Percentage</div>
                <div class="s2">
                  <div class="total">Total Supply</div>
                  <div class="num">1,000,000</div>
                </div>
              </div>
              <div class="line"></div>
              <div class="model2">
                <!--                <div class="item type2">-->
                <!--                  <div class="length" style="width:60%">-->
                <!--                    <span>Liquidity Pool Token</span>-->
                <!--                    <span>40%</span>-->
                <!--                  </div>-->
                <!--                  <div class="tit">9550762b</div>-->
                <!--                </div>-->
                <div class="item" v-for="holder in holders">
                  <div class="length" :style="len(holder.Proportion)">
                    <div>
                      {{ short_address(holder.TokenHolderAddress) }}
                    </div>
                    <b> {{ holder.Proportion }}%</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<style scoped>
.iframe-container {
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 20px;

}

.lw-chart {
  aspect-ratio: 2100 / 1300;
  border-radius: 20px !important;
  overflow: hidden;
}

.tv-lightweight-charts {
  border-radius: 20px !important;
}

#iframe {
  width: 100%;
  height: 100%;
  border: none; /* Ensures no border */
}

.dextools-widget {
  width: 100%;
  height: 400px;
  border-radius: 20px;
}

.icon_img {
  border-radius: 50% !important; /* 将图片变成圆形 */
  object-fit: cover; /* 确保图片覆盖整个圆形区域 */
  overflow: hidden; /* 隐藏超出圆形区域的部分 */
  aspect-ratio: 1 / 1 !important; /* 设置宽高比为 1:1 */
}
</style>