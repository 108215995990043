<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import {mapState} from "vuex";
import {ElMessage} from "element-plus";
import {getSigner} from "@/utils/wallet";
import {launchpad_address} from "@/store";
import {ethers} from "ethers";
import {LaunchPadAbi} from "@/store/LaunchPadAbi";
import axios from "@/utils/axios";

export default {
  name: "CreateTokenView",
  components: {FooterComponent, HeaderComponent},
  data: function () {
    return {
      tokenName: '',
      tokenSymbol: '',
      tokenDescription: '',
      website: '',
      twitter: '',
      telegram: '',
      imageUrl: '',
      initialBuyAmount: '',
      loading: false,
      targetDate: new Date('2026-12-31 23:59:59+8'), // 目标日期和时间
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      intervalId: null,
      currentYear: 0,
      currentMonth: 0,
      currentDay: 0,
      currentHour: 0,
      currentMinute: 0,
      currentSecond: 0,
      am: 'AM',
      eth_price:0,
    }
  },
  computed: {
    ...mapState(['wallet_address', 'wallet_type']), // 映射 state
  },
  methods: {
    updateCurrentTime() {
      const now = new Date();
      this.currentYear = now.getFullYear();
      const monthAbbreviations = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      this.currentMonth = monthAbbreviations[now.getMonth()];
      this.currentDay = now.getDate();
      this.currentHour = now.getHours();
      //转为12小时制
      if (this.currentHour > 12) {
        this.currentHour -= 12;
        this.am = 'PM';
      } else {
        this.am = 'AM';
      }

      if (this.currentHour < 10) {
        this.currentHour = '0' + this.currentHour
      }

      this.currentMinute = now.getMinutes();
      //补0
      if (this.currentMinute < 10) {
        this.currentMinute = '0' + this.currentMinute;
      }
      this.currentSecond = now.getSeconds();

      if (this.currentSecond < 10) {
        this.currentSecond = '0' + this.currentSecond;
      }
      console.log(this.currentSecond)
    },

    async onClickSubmit() {
      // Check if wallet is connected
      if (!this.wallet_address) {
        ElMessage.error('Please connect wallet first.');
        return;
      }

      // Data validation
      if (!this.tokenName) {
        ElMessage.error('Token name is required.');
        return;
      }

      if (!this.tokenSymbol) {
        ElMessage.error('Token symbol is required.');
        return;
      }

      if (!this.tokenDescription) {
        ElMessage.error('Token description is required.');
        return;
      }

      // if (!this.imageUrl) {
      //   ElMessage.error('Token image is required.');
      //   return;
      // }

      try {
        if (this.loading) {
          return
        }
        this.loading = true

        const signerInfo = await getSigner(this.wallet_type); // Get the signer information
        // Check if signerInfo is an array with two elements
        let provider, signer;
        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        if (!this.wallet_address) {
          throw new Error('Wallet address is not defined');
        }

        const factory = new ethers.Contract(launchpad_address, LaunchPadAbi, signer)

        const tx = await factory.createAndInitPurchase(this.tokenName, this.tokenSymbol, {
          value: 0,
        })
        console.log('tx', tx,)
        ElMessage.success('Transaction submitted. Waiting for confirmation...');

        const receipt = await tx.wait();
        let parsedLogs = null;
        console.log('receipt', receipt)
        if (receipt.status === 1) {
          console.log('Order completed successfully.');
          ElMessage.success('Order completed successfully.');

          parsedLogs = receipt.logs.map(log => {
            try {
              const iface = new ethers.Interface(LaunchPadAbi);
              return iface.parseLog(log);
            } catch (error) {
              console.error("Error parsing log:", error);
              return null; // or handle the error as needed
            }
          }).filter(log => log !== null); // Filter out any null values

          console.log('parsedLogs', parsedLogs);

        } else {
          console.log('Order failed.');
          ElMessage.success('Order failed.');
          this.loading = false
          return;
        }

        const url = "/api/create_token";
        const tokenAddress = parsedLogs[0].args[0]
        const resp = await axios.post(url, {
          name: this.tokenName,
          symbol: this.tokenSymbol,
          description: this.tokenDescription,
          website: this.website,
          telegram: this.telegram,
          twitter: this.twitter,
          initialBuyAmount: this.initialBuyAmount,
          iconUrl: this.imageUrl,
          txid: tx.hash,
          tokenAddress: tokenAddress,
          creatorAddress: this.wallet_address
        });
        this.loading = false

        if (resp.status === 200) {
          ElMessage.success('Token created successfully.');
        }


      } catch (e) {
        this.loading = false
        console.log(e)
        if (e.reason) {
          ElMessage.error(e.reason)
        } else if (e.shortMessage) {
          ElMessage.error(("Transaction failed: " + e.shortMessage));
        }

      } finally {
        this.loading = false
      }

    },
    handleAvatarSuccess(response, uploadFile) {
      //接口返回

      //   {
      //     message: "File uploaded successfully", file
      //   :
      //     "http://www.bump.ceo/uploads/1733239919988.jpg"
      //   }
      //   file
      //       :
      //       "http://www.bump.ceo/uploads/1733239919988.jpg"
      //   message
      //       :
      //       "File uploaded successfully"
      // }
      console.log(response, uploadFile)
      this.imageUrl = response.file;
    },
    beforeAvatarUpload(rawFile) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      const maxSizeInMB = 4;

      if (!allowedTypes.includes(rawFile.type)) {
        ElMessage.error('Avatar picture must be in JPG, PNG, GIF, or WEBP format!');
        return false;
      } else if (rawFile.size / 1024 / 1024 > maxSizeInMB) {
        ElMessage.error('Avatar picture size cannot exceed 4MB!');
        return false;
      }

      return true;
    },
    async load() {
      const load_resp = await axios.post('/api/token_list', {
        page: 1,
        pageSize: 1
      })
      if (load_resp.data.code === 0) {
        // this.token_list = load_resp.data.data.tokens
        this.eth_price = load_resp.data.data.eth_price;
        // this.totalPages = Math.ceil(load_resp.data.data.total / this.pageSize); // Calculate total pages
      }
    }
  },
  mounted() {
    this.load();
    this.timer1=setInterval(() => {
      this.updateCurrentTime()
    }, 1000)
  },
  unmounted() {
    clearInterval(this.timer1)
  }
}
</script>

<template>
  <div>
    <HeaderComponent/>
    <div class="index">
      <div class="section6">
        <div class="wrap">
          <div class="left">
            <div class="block1">
              <div class="lef">
                <div class="title">
                  <div class="txt">Extra Options</div>
                  <div class="switchModel">
                    <input type="checkbox" name="Storage" id="switch1" style="color: black">
                    <label for="switch1"><em></em></label>
                  </div>
                </div>
                <div class="sendfile">

                  <el-upload
                      class=""
                      action="https://33bnb.meme/api/upload"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                  >
                    <img :src="imageUrl" v-if="imageUrl" alt="" style="width: 100%;height: 100%"/>
                    <div class="pic" v-if="!imageUrl">
                      <div class="default">
                        <span>jpeg｜png｜webp｜gif ( < 5MB )</span>
                      </div>
                      <button class="btn" type="button">Select Media</button>
                    </div>

                  </el-upload>

                </div>
              </div>
              <div class="rig">
                <div class="title">Token</div>
                <div class="formlist">
                  <input type="text" placeholder="Name" class="words" v-model="tokenName" style="color: black">
                  <input type="text" placeholder="Ticker Symbol" class="words" v-model="tokenSymbol"
                         style="color: black">
                  <input type="text" placeholder="Description" class="words" v-model="tokenDescription"
                         style="color: black">
                </div>
              </div>
            </div>
            <div class="block2">
              <div class="title">Raised Token</div>
              <div class="list">
                <div class="item current">
                  <img src="images/ic2d.png" alt="">
                  <span>BNB</span>
                </div>
                <!--                <div class="item">-->
                <!--                  <img src="images/ic2d.png" alt="">-->
                <!--                  <span>CAKE</span>-->
                <!--                </div>-->
              </div>
            </div>
            <div class="block3">
              <div class="item1">
                <div class="ms1">
                  <div class="tit">Raised Amount</div>
                  <div class="icon"><img src="images/ic2d.png" alt=""></div>
                </div>
                <div class="ms2">
                  <b>25 BNB</b>
                  <span>${{25 * eth_price}}</span>
                </div>
                <div class="ms3">The minimum amount needs to be greater than 25BNB</div>
                <div class="ms4">
                  <p class="current">25 BNB</p>
                  <p>$20000</p>
                  <p>$50000</p>
                </div>
                <div class="ms5"><a href="#">Other</a></div>
              </div>
              <div class="item2">
                <div class="tit">Optional</div>
                <div class="link">
                  <a href="#">
                    <span class="icon"><img src="images/ig1.png" alt=""></span>
                    <input type="text" placeholder="WWW.ABCD.MEME" class="words" v-model="website">
                  </a>
                  <a href="#">
                    <span class="icon"><img src="images/ig2.png" alt=""></span>
                    <input type="text" placeholder="Optional" class="words" v-model="telegram">
                  </a>
                  <a href="#">
                    <span class="icon"><img src="images/ig3.png" alt=""></span>
                    <input type="text" placeholder="Optional" class="words" v-model="twitter">
                  </a>
                </div>
              </div>
            </div>
            <div class="block4">
              <button class="words" @click="onClickSubmit">Submit</button>
            </div>
          </div>
          <div class="right">
            <div class="blocks1">
              <div class="lef">
                <div class="tit">Start Time</div>
                <div class="card">
                  <div class="ms1">
                    <b>{{ currentYear }} <br>{{ currentMonth }}</b>
                    <span>{{ currentDay }}</span>
                  </div>
                  <div class="ms2">
                    <b>{{ am }}</b>
                    <span>{{ currentHour }}:{{ currentMinute }}:{{ currentSecond }}</span>
                  </div>
                </div>
              </div>
              <div class="rig">
                <div class="ms1">
                  <div class="s1">
                    <p>Initial Price</p>
                    <b>BNB</b>
                  </div>
                  <div class="s2">0.0000000077</div>
                </div>
                <div class="ms2">
                  <div class="dec">The biggest increase before list on PancakeSwap</div>
                  <div class="val">+1,436.64%</div>
                </div>
              </div>
            </div>
            <div class="blocks2">
              <div class="tit">Total Supply</div>
              <div class="val">1,000,000,000</div>
            </div>
            <div class="blocks3">The minimum amount needs to be greater than 1,000,000</div>
            <div class="blocks4">
              <div class="tit">Maximum per user</div>
              <div class="vals">
                <input type="text" placeholder="0" class="words">
                <div class="txt">BNB <img src="images/ic2d.png" alt=""></div>
              </div>
            </div>
            <div class="blocks5">
              <div class="tit">
                <p>Sales Ratio</p>
                <p>Liquidity Pool Ratio</p>
              </div>
              <div class="loadings">
                <p class="p1">80%</p>
                <p class="p2"><span>0%</span><b>Reserved Ratio</b></p>
                <p class="p3">20%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<style scoped>

</style>